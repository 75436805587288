import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

export type StorageKeyValues = {
    votedOnReviews: string;
    reviewProgress: { [productId: string]: any };
    displayCurrency: string;
    signedIn: boolean;
};

const KEY_PREFIX = 'kb__';

/**
 * A service for interacting with LocalStorage & SessionStorage in a way which is safe for
 * the server context.
 */
@Injectable({
    providedIn: 'root'
})
export class StorageService {
    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        @Inject(DOCUMENT) private document?: Document,
    ) {}

    setInLocalStorage<T extends keyof StorageKeyValues>(key: T, value: StorageKeyValues[T]) {
        this.setInStorage(key, value, 'localStorage');
    }
    getFromLocalStorage<T extends keyof StorageKeyValues>(key: T): StorageKeyValues[T] | null {
        return this.getFromStorage(key, 'localStorage');
    }
    removeFromLocalStorage<T extends keyof StorageKeyValues>(key: T) {
        this.clearInStorage(key, 'localStorage');
    }
    setInSessionStorage<T extends keyof StorageKeyValues>(key: T, value: StorageKeyValues[T]) {
        this.setInStorage(key, value, 'sessionStorage');
    }
    getFromSessionStorage<T extends keyof StorageKeyValues>(key: T): StorageKeyValues[T] | null {
        return this.getFromStorage(key, 'sessionStorage');
    }
    removeFromSessionStorage<T extends keyof StorageKeyValues>(key: T) {
        this.clearInStorage(key, 'sessionStorage');
    }

    private setInStorage(
        key: keyof StorageKeyValues,
        value: any,
        storage: keyof WindowLocalStorage | keyof WindowSessionStorage,
    ) {
        if (isPlatformBrowser(this.platformId) && this.document && this.document.defaultView) {
            this.document.defaultView[storage].setItem(KEY_PREFIX + key, JSON.stringify(value));
        }
    }

    private getFromStorage(
        key: keyof StorageKeyValues,
        storage: keyof WindowLocalStorage | keyof WindowSessionStorage,
    ): any {
        if (isPlatformBrowser(this.platformId) && this.document && this.document.defaultView) {
            const item = this.document.defaultView[storage].getItem(KEY_PREFIX + key);
            if (item) {
                try {
                    return JSON.parse(item);
                } catch (e) {
                    return null;
                }
            }
        }
    }

    private clearInStorage(
        key: keyof StorageKeyValues,
        storage: keyof WindowLocalStorage | keyof WindowSessionStorage,
    ) {
        if (this.document && this.document.defaultView) {
            this.document.defaultView[storage].removeItem(KEY_PREFIX + key);
        }
    }
}
